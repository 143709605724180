<template>
    <div>
        <div class="flex-space">
            <div class="text-large text-bold">{{ $t('issues.linked_files') }}</div>
            <FileSearchDropdown class="btn-action" @selectFile="createAssociation" :selectedFiles="selectedFiles">
                <i class="fas fa-plus fa-fw"></i>
            </FileSearchDropdown>
        </div>

        <div v-for="a in associations" class="btn-group mr-2 mt-2">
            <router-link v-if="a.association_object" class="btn" target="_blank"
                :to="{ name: 'files.show', params: { id: a.association_object.id } }">
                <div class="text-clamped">{{ a.association_object.scan_code }}</div>
            </router-link>
            <button v-else class="btn text-capitalize text-line-through">{{ $t('i.delete') }}</button>
            <button class="btn btn-action" @click="removeAssociation(a)">
                <i class="far fa-times"></i>
            </button>
        </div>
    </div>
</template>

<script>
import FileSearchDropdown from '~/components/files/FileSearchDropdown.vue'

export default {
    props: { associations: Array },
    components: { FileSearchDropdown },

    data() {
        return {
            issue_number: this.$route.params.issue_number,
        }
    },

    computed: {
        selectedFiles() {
            return this.associations.map(a => a.association_object?.id)
        }
    },

    methods: {
        async createAssociation(file) {
            const association = this.associations.find(a => a.association_object?.id === file.id)

            if (association) {
                return await this.removeAssociation(association)
            }

            const { data } = await this.$axios.post(`issues/${this.issue_number}/associations`, {
                associable_type: 'File', associable_id: file.id, association_object: file
            })

            this.associations.push(data)
            this.$emit('update')
        },

        async removeAssociation(association) {
            if (!association.id) return

            this.associations.splice(this.associations.indexOf(association), 1)
            await this.$axios.patch(`issues/${this.issue_number}/associations/${association.id}`)
            this.$emit('update')
        },
    }
}
</script>