<template>
    <div class="dark-heading">
        <Spinner v-if="!issue.issue_number"></Spinner>

        <div v-else class="issues-show" :class="{ isClosed }">
            <div class="context-bar">
                <div class="context-left">
                    <router-link class="btn btn-sm s-circle" :to="{ name: 'issues' }" exact>
                        <i class="fas fa-arrow-left"></i>
                    </router-link>
                    <h2 @click.prevent="editIssueTitle()" class="c-hand">
                        <span class="mr-2">{{ issue.title }}</span>
                        <i class="far fa-pencil fa-2xs"></i>
                    </h2>
                </div>

                <div class="context-right">
                    <Dropdown class="dropdown-right ml-auto">
                        <div class="menu-item">
                            <a href="#" @click.prevent="editIssueTitle()">{{ $t('issues.edit_title') }}</a>
                        </div>
                        <div class="menu-item" v-if="issue.status">
                            <a href="#" @click.prevent="closeIssue()">{{ $t('issues.close_issue') }}</a>
                        </div>
                        <div class="menu-item" v-else>
                            <a href="#" @click.prevent="openIssue()">{{ $t('issues.reopen_issue') }}</a>
                        </div>
                        <div class="menu-item text-error">
                            <a href="#" @click.prevent="deleteIssue()">{{ $t('main.delete') }}</a>
                        </div>
                    </Dropdown>
                </div>
            </div>

            <div class="box page-header mb-6">
                <table class="page-header-info">
                    <tbody>
                        <tr>
                            <th>{{ $t('issues.number') }}</th>
                            <th>{{ $t('main.created') }}</th>
                            <th>{{ $t('issues.created_by') }}</th>
                            <th>{{ $t('issues.status') }}</th>
                        </tr>
                        <tr>
                            <td>
                                <h2>#{{ issue.issue_number }}</h2>
                            </td>
                            <td>
                                <DateTime :date="issue.created_at"></DateTime>
                            </td>
                            <td>
                                <div class="flex-start">
                                    <Avatar :initials="issue.author.name"></Avatar>
                                    <div class="ml-2">{{ issue.author.name }}</div>
                                </div>
                            </td>
                            <td>
                                <button class="btn" @click="toggleStatus()">
                                    <i class="fas fa-circle" :class="[issue.status ? 'text-error' : 'text-gray']"></i>
                                    <span class="ml-2">{{ $t('issues.issue_status', issue.status) }}</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="columns">
                <div class="column col-8 col-lg-12">
                    <IssueDescription :issue="issue" @update="fetchIssue()" class="mb-6"></IssueDescription>

                    <div class="issue-timeline box mb-4" :class="{ showOnlyComments }">
                        <div class="flex-space mb-6">
                            <h5 class="text-bold m-0 mr-2">{{ $t('issues.activity') }}</h5>
                            <button class="btn btn-link text-gray  ml-2" @click="toggleCommentsOnly()">
                                <i class="fas fa-fw" :class="[showOnlyComments ? 'fa-eye' : 'fa-eye-slash']"></i>
                                <span class="ml-2">{{ $t('issues.show_comments_only') }}</span>
                            </button>
                        </div>
                        <IssueTimeline :timeline="issue.timeline" @update="fetchIssue()"></IssueTimeline>
                    </div>

                    <TextEditor v-model="comment" :placeholder="$t('issues.write_a_comment')" class="mt-4"
                        :buttonText="$t('issues.post_comment')" @save="createComment()">
                    </TextEditor>
                </div>
                <div class="column col-4 col-lg-12">
                    <IssueAssignees v-model="issue.assignments" @update="fetchIssue()"></IssueAssignees>
                    <IssueAssociations :associations="issue.associations" @update="fetchIssue()"></IssueAssociations>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '~/components/Avatar.vue'
import IssueTimeline from '~/components/issues/IssueTimeline.vue'
import IssueAssignees from '~/components/issues/IssueAssignees.vue'
import IssueAssociations from '~/components/issues/IssueAssociations.vue'
import IssueDescription from '~/components/issues/IssueDescription.vue'
import { transformTimeline } from '~/helpers/issues.js'
import { DialogError } from '~/plugins/dialog.js'

export default {
    components: {
        Avatar,
        IssueTimeline,
        IssueAssignees,
        IssueAssociations,
        IssueDescription,
    },

    data() {
        return {
            timer: null,
            issue: { timeline: [] },

            comment: '',
            issue_number: this.$route.params.issue_number,
            showOnlyComments: this.$store.state.issueTimelineShowOnlyComments,
        }
    },

    computed: {
        isClosed() {
            return this.issue.status === 0
        },

        isEditingComment() {
            return this.issue.timeline.some(item => item.edit)
        },
    },

    async mounted() {
        await this.fetchIssue()

        this.timer = setInterval(this.fetchIssue, 10000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },

    methods: {
        async fetchIssue() {
            if (this.isEditingComment) return

            this.$axios.storage.clear()

            const { data: issue } = await this.$axios.get(`issues/${this.issue_number}`)

            // Prevent overwriting the entire issue object 
            // to maintain the current state and avoid state issues
            if (!this.issue.issue_number) this.issue = issue

            this.issue.timeline = transformTimeline(issue.timeline)
        },

        toggleCommentsOnly() {
            this.showOnlyComments = !this.showOnlyComments
            this.$store.commit('UPDATE_ISSUE_TIMELINE_SHOW_ONLY_COMMENTS', this.showOnlyComments)
        },

        editIssueTitle() {
            this.$dialog.confirm(async (title) => {
                try {
                    await this.$axios.patch(`issues/${this.issue_number}`, { title })
                    this.issue.title = title
                    this.fetchIssue()
                } catch ({ response }) {
                    throw new DialogError(response.data?.message)
                }
            }, {
                input: this.issue.title,
                title: this.$t('issues.edit_title'),
                buttonText: this.$t('main.update')
            })
        },

        deleteIssue() {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`issues/${this.issue_number}`)
                this.$toast(this.$root.$t('i.delete', { i: this.issue.title }))
                this.$router.push({ name: 'issues' })
            })
        },

        async createComment() {
            if (this.isClosed) return

            await this.$axios.post(`issues/${this.issue_number}/comments`, { description: this.comment })
            await this.fetchIssue()
            this.comment = ''
        },

        async toggleStatus() {
            await this.issue.status ? this.closeIssue() : this.openIssue()
        },

        async closeIssue() {
            this.$dialog.confirm(async () => {
                await this.$axios.patch(`issues/${this.issue_number}/close`)
                await this.fetchIssue()
                this.issue.status = 0
            }, { buttonText: this.$t('issues.close_issue'), text: this.$t('issues.closing_the_issue_will_prevent_changes') })
        },

        async openIssue() {
            this.$dialog.confirm(async () => {
                await this.$axios.patch(`issues/${this.issue_number}/open`)
                await this.fetchIssue()
                this.issue.status = 1
            }, { buttonText: this.$t('issues.reopen_issue') })
        },
    },
}
</script>